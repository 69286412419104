<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <b-row class="match-height">
              <b-col cols="12">
                <tab-position :tabs="tabs" :name="'Region'" />
              </b-col>
            </b-row> -->
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search :moduleapi="`${this.baseApi}/hrregion`" :columns="columns" title="Region"
                routerpath="/master/hr/addregionmaster" :formOptions="formOptions" :edit="edit" :add="add"
                :canDelete="canDelete" :viewFields="viewFields" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/components/axios';
import GoodTableColumnSearch from "../../../../components/GoodTableColumnSearch.vue";
import TabPosition from "../../../../components/TabView.vue";
import { Dropdown } from 'element-ui';
const baseApi = process.env.VUE_APP_APIENDPOINT;
export default {
  components: {
    GoodTableColumnSearch,
    TabPosition,
  },
  data() {
    return {
      // tabs: [
      //   { name: 'Holiday', route: '/master/hr/holiday', icon: 'CalendarIcon' },
      //   { name: 'HolidayName', route: '/master/hr/holidayname', icon: 'BarChart2Icon' },
      //   { name: 'Region', route: '/master/hr/regionmaster', icon: 'CrosshairIcon' },
      //   { name: 'ApplyPost', route: '/master/hr/applypost', icon: 'HardDriveIcon' },
      //   { name: 'Asset', route: '/master/hr/assetmaster', icon: 'CompassIcon' },
      //   { name: 'Cast', route: '/master/hr/cast', icon: 'CastIcon' },
      //   { name: 'Document', route: '/master/hr/document', icon: 'FileIcon' }
      // ],
      baseApi: process.env.VUE_APP_APIENDPOINT,
      formOptions: {
        formTitle: `${this.$route.params.id ? "Edit Region" : "Add Region"}`,
        // submitButtonName: "Add Region",
        submitRouterPath: "/master/hr/regionmaster",
        branchDropdown: true,
        designationDropdown: true,
        usersDropdown: true,
        gridForm: true,
        getEditValue: `${baseApi}/gethrregionById`,
        method: "post",
        action: "add",
        url: `${baseApi}/hrregion`,
        inputFields: [
          {
            name: "company",
            value: 1,
            type: "hidden",
          },
          {
            name: "active",
            value: 1,
            type: "hidden",
          },
          {
            label: "Name",
            name: "name",
            value: "",
            type: "text",
            placeholder: "Enter Name",
            class: "col-md-12",
            required: true,
          },
          {
            label: "Parent",
            name: "parent",
            value: "",
            type: "dropdown",
            url: "hrregionlist",
            dropdownname: "parent",
            responseValue: "id",
            class: "col-md-12",
            // required: true,
            // rules: 'required',
            options: [],
          },
          {
            label: "Zone",
            name: "zone",
            type: "dropdown",
            value: "",
            placeholder: "None",
            dropdownname: "zone",
            options: ["West", "Center", "North", "East", "South", "HO"],
            class: "col-md-12",
            required: true,
          },
        ],
      },
      viewFields: [
        {
          label: 'Name',
          field: 'name',
          type: 'text',
          class: 'col-md-4'
        },
        {
          label: 'Parent',
          field: 'parent',
          type: 'text',
          class: 'col-md-4'
        },
        {
          label: 'Zone',
          field: 'zone',
          type: 'text',
          class: 'col-md-4'
        },
      ],
      columns: [
        {
          label: "SR NO",
          field: "id",
          filterOptions: {
            enabled: true,
            placeholder: "Search SR NO",
          },
          hidden: true,
        },
        {
          label: "Name",
          field: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search name",
          },
        },

        {
          label: "Parent",
          field: "parent",
          type: "dropdown",
          url: "hrregionlist",
          responseValue: "name",
          filterOptions: {
            enabled: true,
            placeholder: "Search Parent",
          },
        },
        {
          label: "Zone",
          field: "zone",
          filterOptions: {
            enabled: true,
            placeholder: "Search Zone",
          },
        },
        {
          label: "Enter By",
          field: "enterby",
          type: "staticdropdown",
          responseValue: 'fullname',
          url: 'getUsers',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Enter Date",
          field: "enterdatetime",
          type: "datetime",
          time: true,
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Modified By",
          field: "modifiedby",
          type: "staticdropdown",
          responseValue: 'fullname',
          url: 'getUsers',
          filterOptions: {
            enabled: true,
            placeholder: "Search Name",
          },
        },
        {
          label: "Modified Date",
          field: "modifieddatetime",
          time: true,
          type: "datetime",
          filterOptions: {
            enabled: true,
            placeholder: "Search Date",
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      edit: "",
      add: "",
      canDelete: "",
    };
  },
  mounted() {
    const userData = JSON.parse(localStorage.getItem("UserRights"));
    const role = JSON.parse(localStorage.getItem("userData")).role;
    if (role !== "admin") {
      userData.map((item) => {
        if (item.modulename == "Region Master") {
          this.edit = item.edit;
          this.add = item.add;
          this.canDelete = item.delete;

          if (
            item.edit !== 1 &&
            item.add !== 1 &&
            item.delete !== 1 &&
            item.view !== 1
          ) {
            this.$router.push("/");
          }
        }
      });
    }
  },
  beforeMount() {
    this.getDropdowns();
  },
  methods: {
    async getDropdowns() {
      const accessToken = localStorage.getItem("accessToken");
      const baseApi = process.env.VUE_APP_APIENDPOINT;
      this.formOptions.inputFields.map(async (item) => {
        if (item.type == "dropdown" && item.url) {
          const requestOptionsUsers = {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${accessToken}`,
            },
            url: `${baseApi}/${item.url}`,
          };

          await axios(requestOptionsUsers)
            .then((response) => {
              response.data.data.data
                ? (item.options = response.data.data.data)
                : (item.options = response.data.data);

              this.form[item.name] = data[item.name];
              if (item.name == item.dropdownname) {
                item.options.map((user) => {
                  if (user.id == this.form[item.dropdownname]) {
                    item.item = user.name;
                  }
                });
              }
            })
            .catch((error) => console.log(error, "error"));
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
